import useLocalStorage from '@/hooks/useLocalStorage';
import { baseApiUrl, IFetch, ValidMethods, ValidPaths } from '@/shared/config';

function getToken() {
    return useLocalStorage().storedToken;
}

function getUrl(path: ValidPaths, queryParams?: Record<string, any>): string {
    return `${baseApiUrl}${path}${getQueryString(queryParams)}`;
}

function getQueryString(queryParams?: Record<string, any>) {
    if (!queryParams) {
        return '';
    }

    const str = [];
    for (const param in queryParams) {
        if (queryParams.hasOwnProperty(param)) {
            str.push(encodeURIComponent(param) + '=' + encodeURIComponent(queryParams[param]));
        }
    }

    return `?${str.join('&')}`;
}

function getOptions(method: ValidMethods, body?: Record<string, any>, useToken = false): RequestInit {
    return {
        method,
        mode: 'cors',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            ...( useToken && { 'Authorization': `Bearer ${ getToken() }` } )
        },
        body: JSON.stringify(body)
    };
}

export async function doFetch<T>({ body, method = 'GET', path, queryString, useToken }: IFetch): Promise<T> {
    console.debug(`API: ${path}`);
    try {
        const response = await fetch(getUrl(path, queryString), getOptions(method, body, useToken));

        if (!response || !response.ok || !response.body) {
            throw response;
        }

        return await response.json();
    }
    catch (err) {
        console.error(err);
        return Promise.reject();
    }
}