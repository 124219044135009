export default {
    ACCESS_SITE: 'Accetta ed accedi al sito',
    CHANGE_PASSWORD_TITLE: 'Inserisci la tua password corrente e quella nuova',
    CHANGE_PASSWORD: 'Cambia password',
    CONFIRM_PASSWORD: 'Conferma nuova password',
    CURRENT_PASSWORD: 'Password attuale',
    DISCLAIMER: 'Gli utilizzi dei contenuti scaricati devono avvenire nei limiti dei diritti audiovisivi acquisiti.',
    DOWNLOAD: 'download',
    DOWNLOAD_CANCELED: 'Download interrotto.',
    DOWNLOAD_COMPLETED: 'Download terminato con successo.',
    ERRORS: {
        CHANGE_PASSWORD: 'Impossibile cambiare la tua password.',
        LOGIN: 'Credenziali non valide.',
        RESET_PASSWORD: 'Impossibile resettare la tua password.'
    },
    EXPORT_XLS: 'Esporta in Excel',
    CONDITIONS: {
        TITLE: 'Per favore, accetta le seguenti condizioni.',
        BANNER_DISCLAIMER: `Accedendo alla Piattaforma, confermi di essere autorizzato in virtù di un contratto sottoscritto da
        te e/o dal tuo datore di lavoro con Lega Nazionale Professionisti Serie A (“LNPA”) e/o da
        un’Agenzia Intermediaria, licenziataria dei Diritti Audiovisivi appartenenti a LNPA e/o da una
        delle società affiliate alla F.I.G.C. che partecipano al Campionato di Serie A (rispettivamente,
        “Contratto”, “Agenzia Intermediaria” e “Squadre Associate”) e prendi atto che i tuoi diritti
        di accesso e utilizzo della Piattaforma sono regolati e limitati dal Contratto. Accedendo, dichiari
        inoltre di comprendere che i media e i contenuti presenti nella Piattaforma sono protetti da diritti
        di proprietà industriale e intellettuale di titolarità di LNPA e delle Squadre Associate e ti impegni
        a non violare in alcun modo tali diritti e a utilizzare i media e i contenuti ivi disponibili in conformità
        al ed entro i termini previsti dal Contratto.`,
        PRIVACY_INFORMATION: 'Leggi l\'informativa privacy (obbligatorio)',
        PRIVACY_INFORMATION_LINK: '/Informativa_Privacy_ITA.pdf',
        WEBSITE_PRIVACY_POLICY: 'Website privacy policy',
        WEBSITE_PRIVACY_POLICY_LINK: '/Website_Privacy_policy_Portale_Media_ITA.pdf',
        COOKIE_POLICY: 'Cookie policy',
        COOKIE_POLICY_LINK: '/Cookie_policy_Portale_Media_ITA.pdf'
    },
    SELECT_CONTENT: 'Seleziona il contenuto',
    LENGTH: 'durata',
    LISTING: 'Contenuti',
    LOGIN_TITLE: 'Portale Media',
    LOGIN_SUBTITLE: 'Lega Serie A',
    LOGIN: 'Accedi',
    LOGOUT: 'Logout',
    RESET_USER_TITLE: 'Portale Media',
    RESET_USER_SUBTITLE: 'Lega Serie A',
    RESET_USER_MESSAGE: 'Gentile Utente,<br /><br />in ottemperanza alle più recenti policy in materia di sicurezza, è obbligatorio il cambio del <strong>nome utente</strong> (inserire un indirizzo e-mail valido) e della <strong>password</strong>.',
    RESET_USER_BTN_RESET: 'RESET CREDENZIALI',
    RESET_USER_BTN_CANCEL: 'Annulla',
    HOME: 'Home',
    SELECT_LANGUAGE: 'La tua lingua',
    GROUP_TITLES: {
        matches: 'Full Matches',
        highlights90s: 'Highlights 90 sec.',
        highlights03_it: 'Highlights 3 min. (Italiano)',
        highlights03_en: 'Highlights 3 min. (Inglese)',
        highlights05_en: 'Highlights 5 min. (Inglese)',
        tacticalCam: 'Camera Tattica',
        conferences: 'Press Conferences',
        promos: 'Promo Trailers',
        magazines: 'Magazines',
        preMatches: 'Prepartita',
        teamsArrival: 'Teams Arrival',
        webspace: 'Flex',
        extraContent: 'Extra content',
        meltReel: 'Melt Reel',
        archiveContent: 'Archive',
        madeInItaly: 'Made in Italy'
    },
    NEW_PASSWORD: 'Nuova password',
    NOTES: 'note',
    PASSWORD: 'Password',
    //PASSWORD_PLACEHOLDER: 'Inserisci la tua password',
    PROFILE: 'Profilo',
    RELOAD_LISTING: 'Aggiorna i contenuti',
    REPORT: 'Report',
    RESET_PASSWORD_TITLE: 'Inserisci il tuo username per resettare la tua password.',
    RESET_PASSWORD: 'reset password',
    PURGE_CACHE: 'Svuota cache',
    SEARCH_TEXT: 'Scrivi per filtrare...',
    SEARCH_TEXT_FOCUS: '',
    SEARCH_TEXT_PLACEHOLDER: 'Digita per filtrare...',
    SUCCESS: {
        CHANGE_PASSWORD: 'Password cambiata con successo.',
        LOGIN: 'Login effettuato con successo.',
        RESET_PASSWORD: 'Password resettata con successo.',
        PURGE_CACHE: 'Cache svuotata con successo.'
    },
    //USERNAME_PLACEHOLDER: 'Inserisci il tuo nome utente',
    USERNAME: 'Nome utente',
    USERINFO: 'Info',
    CPITA: 'Coppa Italia',
    SERIEA: 'Serie A',
    SCITA: 'Supercup',
    FROM_DATE: 'Da',
    TO_DATE: 'A',
    GET_REPORT: 'Ottieni report',
    REPORT_COLUMNS: {
        DATE: 'Data',
        FILE_NAME: 'Nome file',
        PROD_STD: 'Prod std',
        USER_NAME: 'Nome utente',
        USER_ID: 'Id utente',
        COUNTER: 'N. download',
        PROD_VALUE: 'Prod value'
    },
    GENERIC_ERROR: 'Si è verificato un errore.',
    NO_CONTENT: 'Nessun contenuto disponibile.',
    PROFILE_LABELS: {
        CATEGORY: 'Categoria',
        CATEGORY_NAME: 'Nome Categoria',
        CHAMPIONSHIPS: 'Campionati',
        CONTENTTYPES: 'ContentType',
        EXCLUSIVE_INCLUSION_MATCHES: 'Partite esclusive incluse',
        TEAMS: 'Squadre'
    },
    USER_MANUAL: {
        LINK: '/LNPA_Portale_Media_guida_utente_ITA_2023-24.pdf'
    }
};