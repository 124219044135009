import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import parse from 'html-react-parser';

import { ROUTES } from '@/shared/constants';
import { resetPasswordRedirectUrl } from '@/shared/config';
import { logout, selectUser } from '@/state/user';

import { Row, Col } from 'react-bootstrap';
import LoadingButton from '@/components/loading-button';
import CorneredContainer from '../cornered-container';
import useTranslate from '@/hooks/useTranslate';

function ResetPassword() {
    const translate = useTranslate();
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector(selectUser);

    // Check if the user has passed the login
    if (!user.loaded) {
        cancelResetHandler();
    }

    function resetPasswordHandler() {
        const resetUrl = `${user.value?.auth?.resetPasswordUrl}&RET=${encodeURIComponent(resetPasswordRedirectUrl)}`;
        console.debug('Reset url:' + resetUrl);
        if (resetUrl) {
            window.location.href = resetUrl;
        }
    }

    function cancelResetHandler() {
        dispatch(logout());
        history.replace(ROUTES.LOGIN);
    }

    return (
        <CorneredContainer className="reset-user mt-5" topLeft bottomRight>
            <Row>
                <Col className="reset-user-header">
                    <div className="reset-user-title custom-font-weight-medium"><label className="p-1 m-0">{translate.RESET_USER_TITLE}</label></div>
                    <div className="reset-user-subtitle custom-font-weight-light">{translate.RESET_USER_SUBTITLE}</div>
                </Col>
            </Row>
            <Row>
                <Col>
                <div className="d-flex justify-content-center mt-5">{parse('<div>' + translate.RESET_USER_MESSAGE + '</div>')}</div>
                </Col>
            </Row>
            <Row>
                <Col>
                <div className="d-flex flex-row justify-content-center mt-5 mb-3">
                    <LoadingButton
                        onClick={resetPasswordHandler}
                        text={translate.RESET_USER_BTN_RESET}
                        variant="secondary"
                        className='mr-2'
                        />
                    <LoadingButton
                        onClick={cancelResetHandler}
                        text={translate.RESET_USER_BTN_CANCEL}
                        variant="secondary"
                        className='custom-font-weight-medium ml-2'
                        />
                </div>
                </Col>
            </Row>
        </CorneredContainer>
    );
}

export default ResetPassword;